<template>
  <div class="blog col-sm-12 col-md-12 col-lg-12">
    <div class="container ">
      <div class="fadeInDown" style="padding-top: 70px;">
        <h3 style="font-size: 53px; color: #0267a6"><strong>{{ data.servicios_title }}</strong></h3>
        <br>
        <hr>
      </div>
      <div class="col-sm-12 desktop-only" style="padding-top: 30px;">
        <strong>
          <h4><strong>{{ data.servicios_tonelada }}</strong></h4>
          <br><br>
          <div class="row" style="flex-wrap:wrap !important;">
            <div class="col-lg fadeInLeft" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsLocal.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card1_title }}</div>
                <div class="subtitle">{{ data.servicios_card1_text }}</div>
              </b-card>
            </div>
            <div class="col fadeInLeft" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsForaneo.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card2_title }}</div>
                <div class="subtitle">{{ data.servicios_card2_text }}</div>
              </b-card>
            </div>
            <div class="col fadeIn" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsDirecta.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card3_title }}</div>
                <div class="subtitle">{{ data.servicios_card3_text }}</div>
              </b-card>
            </div>
            <div class="col fadeInRight" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsMilkRun.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card4_title }}</div>
                <div class="subtitle">{{ data.servicios_card4_text }}</div>
              </b-card>
            </div>
            <div class="col fadeInRight" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsExpeditados.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card5_title }}</div>
                <div class="subtitle">{{ data.servicios_card5_text }}</div>
              </b-card>
            </div>
          </div>
        </strong>
      </div>
      <div class="col-sm-12 mobile-only">
        <div v-if="true">
          <div class="row">
            <div class="col" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsLocal.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card1_title }}</div>
                <div class="subtitle">{{ data.servicios_card1_text }}</div>
              </b-card>
            </div>
            <div class="col" style="padding-bottom: 30px;">
                <b-card class="servicios">
                  <div class="icono">
                    <br><img src="./../assets/img/gpsForaneo.svg" alt="" style="width: 50px;"><br><br>
                  </div>
                  <div class="title">{{ data.servicios_card2_title }}</div>
                  <div class="subtitle">{{ data.servicios_card2_text }}</div>
                </b-card>
            </div>
          </div>
          <div v-if="true" class="row">
            <div class="col" style="padding-bottom: 30px;">
                <b-card class="servicios">
                  <div class="icono">
                    <br><img src="./../assets/img/gpsDirecta.svg" alt="" style="width: 50px;"><br><br>
                  </div>
                  <div class="title">{{ data.servicios_card3_title }}</div>
                  <div class="subtitle">{{ data.servicios_card3_text }}</div>
                </b-card>
            </div>
            <div class="col" style="padding-bottom: 30px;">
                <b-card class="servicios">
                  <div class="icono">
                    <br><img src="./../assets/img/gpsMilkRun.svg" alt="" style="width: 50px;"><br><br>
                  </div>
                  <div class="title">{{ data.servicios_card4_title }}</div>
                  <div class="subtitle">{{ data.servicios_card4_text }}</div>
                </b-card>
            </div>
          </div>
          <div v-if="true" class="row">
            <div class="col-sm-6" style="padding-bottom: 30px;">
              <b-card class="servicios">
                <div class="icono">
                  <br><img src="./../assets/img/gpsExpeditados.svg" alt="" style="width: 50px;"><br><br>
                </div>
                <div class="title">{{ data.servicios_card5_title }}</div>
                <div class="subtitle">{{ data.servicios_card5_text }}</div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <br><br>
      <hr>
      <div class="col-sm-12" style="padding-bottom: 30px;">
        <div class="introduccion">
          <strong>{{ data.servicios_title2 }}</strong>
        </div>
        <div class="row">
          <div class="col-lg col-md-6 col-sm-12 fadeInDown" style="padding-bottom: 30px;">
            <b-card class="puntos text-center mx-auto">
              <div class="numero">01</div>
              <div>{{ data.servicios_circle1 }}</div>
            </b-card>
          </div>
          <div class="col fadeInUp" style="padding-bottom: 30px;">
            <b-card class="puntos text-center mx-auto">
              <div class="numero">02</div>
              <div>{{ data.servicios_circle2 }}</div>
            </b-card>
          </div>
          <div class="col fadeInDown" style="padding-bottom: 30px;">
            <b-card class="puntos text-center mx-auto">
              <div class="numero">03</div>
              <div>{{ data.servicios_circle3 }}</div>
            </b-card>
          </div>
          <div class="col fadeInUp" style="padding-bottom: 30px;">
            <b-card class="puntos text-center mx-auto">
              <div class="numero">04</div>
              <div>{{ data.servicios_circle4 }}</div>
            </b-card>
          </div>
          <div class="col fadeInDown" style="padding-bottom: 30px;">
            <b-card class="puntos text-center mx-auto">
              <div class="numero">05</div>
              <div>{{ data.servicios_circle5 }}</div>
            </b-card>
          </div>
        </div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog',
  data() {
    return {
      data: JSON.parse(window.localStorage.getItem('lang')),
    };
  },
};
</script>

<style scoped>
.desktop-only {
  display: none;
}
.mobile-only {
 display: none;
}

.servicios {
  min-height: 250px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.title {
  font-weight: bolder;
  font-size: 18px;
  color: #0167a6;
}
.subtitle {
  font-weight: lighter;
  font-size: 15px;
}
.icono {
  font-weight: bolder;
}

.blog {
  padding-top: 140px;
  padding-bottom: 40px;
}

.puntos {
  border-radius: 200px;
  width: 190px;
  height: 200px;
  font-weight: bolder;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #eeeeee;
}

.numero {
  font-size: 55px;
  color: #e0293a;
}

.introduccion {
  font-size: 25px;
  font-weight: bold;
  margin-top: 50px !important;
  margin-bottom: 50px;
}

/* Media queries */
@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
  .servicios {
    min-height: 250px;
    max-height: 250px;
    width: 100%; /* Ajuste para dispositivos móviles */
    box-shadow: none; /* Ajuste para dispositivos móviles */
  }
  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
  }
  .numero {
    font-size: 40px;
  }
  .puntos {
    width: 180px;
    height: 180px;
  }
}
</style>